<template>
  <div>

    <el-row>
      <h3>{{ $t("title.orderinfo") }}</h3>
      <el-form ref="creatermaForm" :model="creatermaForm" :rules="rules" label-width="140px">
        <el-col :span="10">
          <el-form-item :label="$t('topic.PlatformOrderNumber')" prop="platFormName">
            <el-input v-model.trim="creatermaForm.orderCode" :placeholder="$t('title.Afterentering')" @keyup.enter.native="pickOrdersearch()" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.platform')" prop="platFormName">
            <el-input v-model="creatermaForm.platFormName " disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.site')" prop="siteCode">
            <el-input v-model="creatermaForm.siteCode" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.shopName')" prop="shopCode">
            <el-input v-model="creatermaForm.shopCode" disabled />
          </el-form-item>
        </el-col>
        <!--         <el-col :span="10">
          <el-form-item :label="$t('topic.BuyerID')" prop="buyerUsername">
            <el-input v-model="creatermaForm.buyerUsername" disabled />
          </el-form-item>
        </el-col> -->
        <el-col :span="20">
          <el-form-item :label="$t('topic.RMAType')" prop="returnDetailType">
            <el-radio-group v-model="creatermaForm.returnDetailType">
              <el-radio label="0">{{ $t('topic.Refunds') }}</el-radio>
              <el-radio label="1">{{ $t('topic.OnlyRefund') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item :label="$t('topic.RMAWay')" prop="returnType">
            <el-radio-group v-model="creatermaForm.returnType">
              <el-radio disabled label="0">{{ $t('topic.System') }}</el-radio>
              <el-radio label="1">{{ $t('topic.Manual') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <h3>{{ $t('title.Refundinformation') }}</h3>
      <el-form ref="creatermaForm" :model="creatermaForm" :rules="rules" label-width="130px">
        <el-col :span="20">
          <el-form-item :label="$t('topic.Typerefund')" prop="refundType">
            <el-radio-group v-model="creatermaForm.refundType" @change="changeRadio">
              <span>
                <el-radio label="1">{{ $t('topic.Fullrefund') }}</el-radio>
                <el-radio label="2">{{ $t('topic.Partialrefund') }}</el-radio>
                <el-radio label="3">{{ $t('topic.Refuse') }}</el-radio>
              </span>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <h3>{{ $t('title.Productinformation') }}</h3>
      <template>
        <el-table
          ref="multipleTable"
          :data="orderData.itemList"
          default-expand-all
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
          />
          <el-table-column
            :label="$t('title.Refundinformation')"
            align="center"
          >
            <template slot-scope="props">
              <div style="display:flex">
                <el-image
                  :src="props.row.mainImage"
                  class="mr-3"
                  style="width: 45px; height: 45px; vertical-align: middle"
                />
                <p style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ props.row.sku }}</p>
              </div></template>
          </el-table-column>
          <el-table-column
            :label="$t('topic.Numberofrefunds')"
            align="center"
            prop="itemQuantity"
            width="100"
          >
            <template slot-scope="props">
              <p style="display:flex;align-items: center;"> <el-input v-model="props.row.itemQuantity" oninput="value=value.replace(/[^0-9.]/g,'')" @change="changNumbers(props.$index)" @input="handlecChangeInput(props.$index)" /></p>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('topic.reasonforreturn')"
            align="center"
            width="200"
          >
            <template slot-scope="props">
              <el-cascader
                v-model="props.row.returnReasonCode"
                :options="returnReasonOptions"
                :props="reasonProps"
                clearable
                filterable
              />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('title.orderamount')"
            width="150"
          >
            <template slot-scope="props">
              <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.itemPrice }}</span></p>
            </template>
          </el-table-column>

        </el-table>
        <el-form ref="creatermaForm" :model="creatermaForm" label-width="150px" style="margin-top:10px">
          <el-col :span="15">
            <el-form-item :label="$t('title.Buyerpessage')">
              <el-input
                v-model="creatermaForm.sellComments"
                :placeholder="$t('page.inputPlaceholder')"
                :rows="2"
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item :label="$t('title.stytemNote')">
              <el-input
                v-model="creatermaForm.remark"
                :placeholder="$t('page.inputPlaceholder')"
                :rows="2"
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :class="$i18n.locale" :label="$t('title.Buyerpicture')">
              <el-upload
                ref="upload"
                :action="action"
                :before-remove="beforeRemoved"
                :file-list="sellerFileList"
                :headers="uploadHeaders"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                list-type="picture-card"
              >
                <i class="el-icon-upload" />
              </el-upload>
              <el-dialog :visible.sync="imgdialogVisible" append-to-body>
                <img :src="dialogImageUrl" alt="" width="100%">
              </el-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :class="$i18n.locale" :label="$t('title.Attachpicture')">
              <el-upload
                :action="action"
                :before-remove="beforeRemoved1"
                :file-list="brcfilelist"
                :headers="uploadHeaders"
                :on-preview="handlePictureCardPreview1"
                :on-remove="handleRemove1"
                :on-success="handleSuccess1"
                list-type="picture-card"
              >

                <i class="el-icon-upload" />
              </el-upload>
              <el-dialog :visible.sync="imgdialogVisible1">
                <img :src="dialogImageUrl1" alt="" width="100%">
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-form>
      </template>

    </el-row>
    <el-row :span="24" justify="end" style="margin-top: 20px" type="flex">
      <el-col :span="3">
        <el-button @click="goBack">{{ $t('title.back') }}</el-button>
      </el-col>
      <el-col :span="3">
        <el-button :loading="saveLoding" type="primary" @click="saveReturn(1)">{{ $t('title.Save') }}</el-button>
      </el-col>
      <el-col :span="4">
        <el-button :loading="saveLoding" type="primary" @click="submitReturn(2)">{{ $t('title.Release') }}</el-button>
      </el-col>
    </el-row>

    <!-- 物流跟踪号 -->
    <el-dialog
      :title="$t('topic.Agreetoreturn')"
      :visible.sync="buyLableDialogVisible"
      width="60%"
    >
      <el-row :span="24">
        <p />
        <el-form ref="ebayForm" :model="ebayForm" label-width="130px">
          <el-col :span="15">
            <el-form-item :label="$t('topic.logisticsprovider')">
              <el-input v-model="ebayForm.returnCarrier" />
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item :label="$t('topic.trackingnumber')">
              <el-input v-model="ebayForm.trackingNumber" oninput="value=value.replace(/[^\a-\z\A-\Z0-9]/g, '')" />
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="buyLableDialogVisible = false">{{ $t('title.cancel') }}</el-button>
        <el-button :loading="saveLoding" type="primary" @click="confirmDefund(3)">{{ $t('page.sure') }}</el-button>
      </span>
    </el-dialog>
    <Refunds v-model="avisible" />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import Refunds from '../../../rma-manage/components/Refunds'
import {
  checkEbayOrderCode,
  getDetailReturnsInfoById,
  getOmsSelfDeliveryOrderItemTwoLevelGetAmount,
  getReasonCodeByPlatform,
  saveReturnInfos
} from '@/api/omsorder'
import mixin from '@/mixin/oms-mixin'

export default {
  name: 'RMALableDialog',
  components: { Refunds },
  mixins: [mixin],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => ({})
    }

  },
  data() {
    return {
      language: '',
      imgdialogVisible1: false,
      saveLoding: false,
      avisible: false,
      disabled: true,
      dialogImageUrl: '',
      dialogImageUrl1: '',
      sellerFileList: [],
      brcfilelist: [],
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      sellerImageUrl: [],
      imageUrl: [],
      returnReasonOptions: [],
      reasonProps: {
        emitPath: false,
        value: 'value',
        label: 'label',
        children: 'childNodes'
      },
      action: process.env.VUE_APP_UPLOAD_API,
      imgdialogVisible: false,
      orderData: [],
      detailList: [],
      buyLableDialogVisible: false,
      rmaId: '',
      flag: true,
      warehouseOptions: [],
      creatermaForm: {
        orderCode: '',
        siteCode: '',
        shopCode: '',
        platFormName: '',
        buyerUsername: '',
        shipperAddress: '',
        returnDetailType: '1',
        returnType: '1',
        refundType: '1'

      },
      ebayForm: {
        trackingNumber: '',
        returnCarrier: ''
      },
      id: '',
      rules: {
        orderCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        siteCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        shopCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        platFormName: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        buyerUsername: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        shipperAddress: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnDetailType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        refundType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnReasonCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]
      }
    }
  },
  mounted() {
    const lang = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    const erplang = this.$store.getters.language && this.$store.getters.language === 'en' ? 'en-US' : 'zh-CN'
    this.language = erplang || lang
    this._getDetailReturnsInfoById()
  },
  methods: {
    // 添加选中事件
    changeRadio(val) {
      this.$forceUpdate()
    },
    // 选中明细数据val
    handleSelectionChange(val) {
      this.detailList = val
      this.detailList.map(e => {
        e.otherRefund = 0
        e.refundAmount = parseFloat(e.itemPrice) - Math.abs(parseFloat(e.promotionDiscount)) + parseFloat(e.otherRefund)
      })
    },

    // 在输入框输入单号后点击回车调接口查询单号对应的数据
    pickOrdersearch(parames) {
      console.log(this.creatermaForm.orderCode)
      this._getOmsSelfDeliveryOrderItemTwoLevelGetAmount({ orderNumber: this.creatermaForm.orderCode })
    },

    // 点击单号获取对应的数据信息
    async _getOmsSelfDeliveryOrderItemTwoLevelGetAmount(parames) {
      const { datas } = await getOmsSelfDeliveryOrderItemTwoLevelGetAmount(parames)
      if (datas) {
        this.orderData = datas
        this.orderData.itemList.map(e => {
          e.MyitemQuantity = e.itemQuantity
          e.orderCode = e.orderNumber ? e.orderNumber : e.orderCode
        })
        const { platform, site, merchant, buyerName } = datas
        this.creatermaForm.siteCode = site
        this.creatermaForm.shopCode = merchant
        this.creatermaForm.platFormName = platform
        this.creatermaForm.buyerUsername = buyerName
      }
      this._getAllReasonCode(this.creatermaForm.platFormName)
    },

    // 通过id 查询对应数据
    async _getDetailReturnsInfoById(query) {
      const { id } = this.$route.query
      if (id) {
        const { id } = this.$route.query
        const { datas } = await getDetailReturnsInfoById(id)
        this.orderData = datas
        this.orderData.itemList = datas.detailList
        this.rmaId = this.orderData.rmaId
        this.id = this.orderData.id
        const { rmaId, returnDetailType, orderCode, returnType, platFormName, shopCode, siteCode, remark, sellComments, buyerUsername } = datas
        this.creatermaForm = { rmaId, returnDetailType: String(returnDetailType), orderCode, remark, returnType: String(returnType), platFormName, shopCode, siteCode, sellComments, buyerUsername }
        this.orderData.itemList.map(e => {
          this.creatermaForm.refundType = String(e.refundType)
          e.itemQuantity = e.returnNumber
          e.orderCode = e.orderNumber ? e.orderNumber : e.orderCode
          e.returnReasonCode = e.returnReasonCodeEN + '@&' + e.returnReasonCode
        })
        this.$nextTick(() => {
          this.orderData.itemList.forEach(i => {
            this.$refs['multipleTable'].toggleRowSelection(i, true)
          })
        })
        this.orderData.imageList.map(e => {
          if (e.imageType === 0) {
            const index = e.imageUrl.lastIndexOf('\/')
            const fileName = e.imageUrl.substring(index + 1, e.imageUrl.length)
            this.sellerFileList.push({
              name: fileName,
              url: e.imageUrl
            })
          } else {
            const index = e.imageUrl.lastIndexOf('\/')
            const fileName = e.imageUrl.substring(index + 1, e.imageUrl.length)
            this.brcfilelist.push({
              name: fileName,
              url: e.imageUrl
            })
          }
        })
        this._getAllReasonCode(this.creatermaForm.platFormName)
      }
    },
    // 获退款退货原因
    async _getAllReasonCode(platform) {
      const lang = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
      const erplang = this.$store.getters.language && this.$store.getters.language === 'en' ? 'en-US' : 'zh-CN'
      const language = erplang || lang
      const isChinese = language === 'zh-CN'
      const { datas } = await getReasonCodeByPlatform(platform)
      this.returnReasonOptions = datas.map(item => {
        item.label = isChinese ? item.reasonCodeFirstCn : item.reasonCodeFirstEn
        item.value = item.reasonCodeFirstEn
        if (item.childNodes && item.childNodes.length > 0) {
          item.childNodes = item.childNodes.map(child => {
            child.label = isChinese ? child.reasonCodeSecondCn : child.reasonCodeSecondEn
            child.value = child.reasonCodeSecondEn + '@&' + child.reasonCodeSecondPlatform
            return child
          })
        }
        return item
      })
    },

    // 保存创建的RMA单
    saveReturn() {
      if (this.detailList.length <= 0) {
        this.$confirm(this.$t('tips.Product'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        return
      }
      this._checkEbayOrderCode(this.creatermaForm.orderCode, 1)
    },
    // 点击单号获取对应的数据信息
    async _checkEbayOrderCode(parames, commitType) {
      const { datas, code } = await checkEbayOrderCode(parames)
      if (code === 0 && datas) {
        this.$confirm(datas, this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        }).then(() => {
          if (commitType === 1) {
            const data = {}
            var imgList = []
            this.$refs['creatermaForm'].validate((valid) => {
              if (valid) {
                console.log(this.sellerFileList)
                this.sellerFileList.map(e => {
                  imgList.push({
                    imageUrl: e.url,
                    imageType: 0
                  })
                })
                this.brcfilelist.map(e => {
                  imgList.push({
                    imageUrl: e.url,
                    imageType: 1
                  })
                })

                const { remark, returnDetailType, orderCode, returnType, siteCode, shopCode, sellComments, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
                this.detailList.map(e => {
                  e.returnNumber = e.itemQuantity
                  e.refundType = Number(this.creatermaForm.refundType)
                })
                data.detailList = this.detailList.map(e => {
                  return {
                    ...e,
                    returnReasonCodeEN: e.returnReasonCode?.split('@&')?.[0],
                    returnReasonCode: e.returnReasonCode?.split('@&')?.[1]
                  }
                })
                // [{ 'imageUrl': this.sellerImageUrl, 'imageType': 0 }]
                data.imageList = imgList
                const id = this.id
                const rmaId = this.rmaId
                if (this.id) {
                  data.returnsInfo = { rmaId, id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
                } else {
                  data.returnsInfo = { remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
                }
                data.returnsTrack = { shipperAddress, orderCode }
                data.operating = 0
                console.log(data)
                this._saveReturnInfos(data)
              }
            })
          } else {
            const data = {}
            const imgList = []
            if (this.creatermaForm.refundType === '1' || this.creatermaForm.refundType === '2' || this.creatermaForm.refundType === '3') {
              this.$refs['creatermaForm'].validate((valid) => {
                if (valid) {
                  this.sellerFileList.map(e => {
                    imgList.push({ imageUrl: e.url,
                      imageType: 0 })
                  })
                  this.brcfilelist.map(e => {
                    imgList.push({ imageUrl: e.url,
                      imageType: 1 })
                  })
                  const { remark, returnDetailType, sellComments, orderCode, returnType, siteCode, shopCode, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
                  this.detailList.map(e => {
                    e.returnNumber = e.itemQuantity
                    e.refundType = Number(this.creatermaForm.refundType)
                  })
                  data.detailList = this.detailList.map(e => {
                    return {
                      ...e,
                      returnReasonCodeEN: e.returnReasonCode?.split('@&')?.[0],
                      returnReasonCode: e.returnReasonCode?.split('@&')?.[1]
                    }
                  })
                  data.imageList = imgList
                  const id = this.id
                  const rmaId = this.rmaId
                  if (this.id) {
                    data.returnsInfo = { rmaId, id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
                  } else {
                    data.returnsInfo = { remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
                  }
                  data.returnsTrack = { shipperAddress, orderCode }
                  data.operating = 1

                  console.log(data)
                  sessionStorage.setItem('indatas', JSON.stringify(data))
                  this.$router.push({
                    path: '../ebay-refunds',
                    append: true,
                    query: { 'repostType': 3, ...this.userInfo }
                  })
                }
              })
            } else {
              this.buyLableDialogVisible = true
            }
          }
        }).catch(() => {
          this.$router.go(-1)
        })
      } else {
        if (commitType === 1) {
          const data = {}
          var imgList = []
          this.$refs['creatermaForm'].validate((valid) => {
            if (valid) {
              console.log(this.sellerFileList)
              this.sellerFileList.map(e => {
                imgList.push({
                  imageUrl: e.url,
                  imageType: 0
                })
              })
              this.brcfilelist.map(e => {
                imgList.push({
                  imageUrl: e.url,
                  imageType: 1
                })
              })

              const { remark, returnDetailType, orderCode, returnType, siteCode, shopCode, sellComments, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
              this.detailList.map(e => {
                e.returnNumber = e.itemQuantity
                e.refundType = Number(this.creatermaForm.refundType)
              })
              data.detailList = this.detailList.map(e => {
                return {
                  ...e,
                  returnReasonCodeEN: e.returnReasonCode?.split('@&')?.[0],
                  returnReasonCode: e.returnReasonCode?.split('@&')?.[1]
                }
              })
              // [{ 'imageUrl': this.sellerImageUrl, 'imageType': 0 }]
              data.imageList = imgList
              const id = this.id
              const rmaId = this.rmaId
              if (this.id) {
                data.returnsInfo = { rmaId, id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
              } else {
                data.returnsInfo = { remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
              }
              data.returnsTrack = { shipperAddress, orderCode }
              data.operating = 0
              console.log(data)
              this._saveReturnInfos(data)
            }
          })
        } else {
          const data = {}
          const imgList = []
          if (this.creatermaForm.refundType === '1' || this.creatermaForm.refundType === '2' || this.creatermaForm.refundType === '3') {
            this.$refs['creatermaForm'].validate((valid) => {
              if (valid) {
                this.sellerFileList.map(e => {
                  imgList.push({ imageUrl: e.url,
                    imageType: 0 })
                })
                this.brcfilelist.map(e => {
                  imgList.push({ imageUrl: e.url,
                    imageType: 1 })
                })
                const { remark, returnDetailType, sellComments, orderCode, returnType, siteCode, shopCode, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
                this.detailList.map(e => {
                  e.returnNumber = e.itemQuantity
                  e.refundType = Number(this.creatermaForm.refundType)
                })
                data.detailList = this.detailList.map(e => {
                  return {
                    ...e,
                    returnReasonCodeEN: e.returnReasonCode?.split('@&')?.[0],
                    returnReasonCode: e.returnReasonCode?.split('@&')?.[1]
                  }
                })
                data.imageList = imgList
                const id = this.id
                const rmaId = this.rmaId
                if (this.id) {
                  data.returnsInfo = { rmaId, id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
                } else {
                  data.returnsInfo = { remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
                }
                data.returnsTrack = { shipperAddress, orderCode }
                data.operating = 1

                console.log(data)
                sessionStorage.setItem('indatas', JSON.stringify(data))
                this.$router.push({
                  path: '../ebay-refunds',
                  append: true,
                  query: { 'repostType': 3, ...this.userInfo }
                })
              }
            })
          } else {
            this.buyLableDialogVisible = true
          }
        }
      }
    },
    // 发布
    submitReturn() {
      if (this.detailList.length <= 0) {
        this.$confirm(this.$t('tips.Product'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        return
      }
      this._checkEbayOrderCode(this.creatermaForm.orderCode, 2)
    },
    // 退款类型为同意退货退款时弹出输入跟踪号弹框 确认发布
    confirmDefund() {
      const data = {}
      const imgList = []
      this.$refs['creatermaForm'].validate(async(valid) => {
        if (valid) {
          this.sellerFileList.map(e => {
            imgList.push({ imageUrl: e.url,
              imageType: 0 })
          })
          this.brcfilelist.map(e => {
            imgList.push({ imageUrl: e.url,
              imageType: 1 })
          })
          const { remark, returnDetailType, sellComments, orderCode, returnType, siteCode, shopCode, platFormName, buyerUsername } = this.creatermaForm
          this.detailList.map(e => {
            e.returnNumber = e.itemQuantity
            e.orderCode = e.orderNumber
            e.refundType = Number(this.creatermaForm.refundType)
          })
          data.detailList = this.detailList.map(e => {
            return {
              ...e,
              returnReasonCodeEN: e.returnReasonCode?.split('@&')?.[0],
              returnReasonCode: e.returnReasonCode?.split('@&')?.[1]
            }
          })
          data.imageList = imgList
          const id = this.id
          const rmaId = this.rmaId
          if (this.id) {
            data.returnsInfo = { rmaId, id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
          } else {
            data.returnsInfo = { remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
          }
          // data.returnsInfo = { remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
          const { trackingNumber, returnCarrier } = this.ebayForm
          data.returnsTrack = { trackingId: trackingNumber, returnCarrier }
          data.operating = 1
          this._saveReturnInfos(data)
        }
      })
    },
    // 保存或者发布后的返回信息
    async _saveReturnInfos(data) {
      try {
        this.saveLoding = true
        const { code, msg } = await saveReturnInfos(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.buyLableDialogVisible = false
          this.$router.push({
            path: '../../ebay-return-manage',
            append: true,
            query: { ...this.userInfo }
          })
        }
      } finally {
        this.saveLoding = false
      }
    },

    // 点击已上传的图片时的方法 看大图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.imgdialogVisible = true
    },
    // 上传图片成功时的方法
    handleSuccess(files, filelist) {
      // 将上传成功的图片放到提交给后台的参数中
      this.sellerFileList.push({
        name: filelist.name,
        url: files.datas
      })
    },
    // 移除图片之前的方法
    beforeRemoved(file, filelist) {
      return this.$confirm(`${this.$t('page.SureDelete')} ${file.name}？`)
    },
    // 移除图片之前的方法
    beforeRemoved1(file, filelist) {
      return this.$confirm(`${this.$t('page.SureDelete')} ${file.name}？`)
    },
    // 点击已上传的图片时的方法 看大图
    handlePictureCardPreview1(file) {
      this.dialogImageUrl1 = file.url
      this.imgdialogVisible1 = true
    },
    // 上传图片成功时的方法
    handleSuccess1(files, filelist) {
      // 将上传成功的图片放到提交给后台的参数中
      this.brcfilelist.push({
        name: filelist.name,
        url: files.datas
      })
    },
    handleRemove(file, fileList) {
      if (file.status === 'success') {
        this.sellerFileList = fileList
      }
    },
    handleRemove1(file, fileList) {
      // this.hideUpload1 = fileList.length >= this.limitCount1
      if (file.status === 'success') {
        if (file.status === 'success') {
          this.brcfilelist = fileList
        }
      }
    },

    changeInput(index) {
      var data = this.detailList[index]
      this.$set(this.detailList, index, data)
    },
    handlecChangeInput(index) {
      var data = this.orderData.itemList[index]
      this.$set(this.orderData.itemList, index, data)
    },
    changeNumber(index) {
      var data = this.detailList[index]
      // 订单金额不能大于原本金额
      if (parseFloat(data.itemPrice) > data.myItemPrice) {
        this.$confirm(this.$t('tips.EnterOriginal'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemPrice = data.myItemPrice
        return
      }
      if (parseFloat(data.otherRefund) > 20) {
        this.$confirm(this.$t('tips.LesserThan20'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.otherRefund = 0
        return
      }
      // 计算总金额
      data.refundAmount = (parseFloat(data.itemPrice) - Math.abs(parseFloat(data.promotionDiscount)) + parseFloat(data.otherRefund) - parseFloat(data.shippingPrice)).toFixed(2)
      this.$set(this.detailList, index, data)
    },
    changNumbers(index) {
      console.log(11111)
      var data = this.orderData.itemList[index]
      console.log(data)
      console.log(Number(data.itemQuantity))
      // 订单金额不能大于原本金额
      if (Number(data.itemQuantity) > data.MyitemQuantity) {
        this.$confirm('您输入得数量不能大于原始数量', this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemQuantity = data.MyitemQuantity
        return
      }
      console.log(Number(data.itemQuantity) <= 0)
      if (Number(data.itemQuantity) <= 0) {
        this.$confirm('您输入得数量不能小于0', this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemQuantity = data.MyitemQuantity
        return
      }
      this.$set(this.orderData.itemList, index, data)
    },
    // 返回
    goBack() {
      this.$router.push({
        path: '../../ebay-return-manage',
        append: true,
        query: { ...this.userInfo }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-upload--picture-card {
    width: 75px;
    height: 75px;
    cursor: pointer;
    line-height:75px;
}
.hide .el-upload--picture-card {
  display: none;
}
</style>
