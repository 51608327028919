<template>
  <div>
    <!-- 退款弹窗 -->
    <el-dialog
      title="退款类型操作"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-row>
        <!-- <p v-if="creatermaForm.refundType === '1'">全部退款</p>
        <p v-if="creatermaForm.refundType === '2'">部分退款</p> -->
        <p><span>订单号:</span><span>{{ info.orderCode }}</span></p>
        <p><span>创建时间:</span><span>{{ info.returnCreateTime }}</span></p>
        <p><span>买家ID:</span><span>{{ info.buyerUsername }}</span></p>
        <template>
          <el-table
            id="refund"
            :data="detailList"
            default-expand-all
            style="width: 100%"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <p style="display: flex;align-items: center;"><span style="display: inline-block;width: 81px;">系统备注</span>
                  <el-input
                    v-model="props.row.sellComments"
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    style="width: 350px;"
                  /></p>
              </template>
            </el-table-column>

            <el-table-column
              label="退款信息"
            >
              <template slot-scope="props">
                <div style="display:flex">
                  <el-image
                    :src="props.row.mainImage"
                    style="width: 35px; height: 35px; vertical-align: middle"
                    class="mr-3"
                  />
                  <p style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ props.row.sku }}</p>
                </div></template>
            </el-table-column>
            <el-table-column
              prop="returnNumber"
              label="退款数量"
              width="80"
            />
            <el-table-column
              label="退款原因"
              width="160"
            >
              <template slot-scope="props">
                <el-select
                  v-model="props.row.returnReasonCodeCN"
                  disabled
                  clearable
                  filterable
                  :placeholder="$t('page.selectPlaceholder')"
                >
                  <el-option
                    v-for="item in returnReasonOptions"
                    :key="item.returnReasonCodeEN"
                    :label="item.returnReasonCodeCN"
                    :value="item.returnReasonCodeCN"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="订单金额"
              width="100"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.itemPrice" oninput="value=value.replace(/[^0-9.]/g,'')" @input="changeInput(props.$index)" @change="changeNumber(props.$index)" /></p>
              </template>
            </el-table-column>
            <el-table-column
              prop="itemTax"
              label="税费"
              width="80"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.itemTax?props.row.itemTax:0 }}</span></p>
              </template>
            </el-table-column>
            <el-table-column
              label="运费退费"
              width="100"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.shippingPrice" oninput="value=value.replace(/[^0-9.]/g,'')" @input="changeInput(props.$index)" @change="changeNumber(props.$index)" /></p>
              </template>
            </el-table-column>
            <el-table-column
              label="促销折扣"
              width="100"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.promotionDiscount" oninput="value=value.replace(/[^0-9.]/g,'')" @input="changeInput(props.$index)" @change="changeNumber(props.$index)" /></p>
              </template>
            </el-table-column>
            <el-table-column
              label="额外退费"
              width="100"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.otherRefund" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="20" @input="changeInput(props.$index)" @change="changeNumber(props.$index)" /></p>
              </template>
            </el-table-column>
            <el-table-column
              prop="refundAmount"
              label="总金额"
              width="100"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.refundAmount }}</span></p>
              </template>
            </el-table-column>

          </el-table>
        </template>

      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundDialogVisible = fasle">取 消</el-button>
        <el-button type="primary" @click="saveRefund">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveReturnInfos } from '@/api/omsorder'

export default {
  name: 'Refunds',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      creatermaForm: {},
      detailList: []
      // visible: this.value
    }
  },
  computed: {
    visible: {
      get() {
        console.log(this.value)
        return this.value
      },
      set(val) {
        console.log(val)

        this.$emit('input', val)
      }
    }
  },
  watch: {
    'value'(visible) {
      console.log(this.info)
      console.log(visible)
      this.detailList = this.info.detailList
    }
  },
  mounted() {
    console.log(this.value)
  },
  methods: {
    saveRefund() {

    },

    // 保存创建的RMA单
    saveReturn() {
      if (this.detailList.length <= 0) {
        this.$confirm('商品信息不能为空', '提示', {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        return
      }

      const data = {}
      var imgList = []
      this.$refs['creatermaForm'].validate(async(valid) => {
        if (valid) {
          console.log(this.sellerFileList)
          this.sellerFileList.map(e => {
            imgList.push({
              imageUrl: e.url,
              imageType: 0
            })
          })
          this.brcfilelist.map(e => {
            imgList.push({
              imageUrl: e.url,
              imageType: 1
            })
          })

          const { remark, returnDetailType, orderCode, returnType, siteCode, shopCode, sellComments, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
          this.detailList.map(e => {
            e.returnNumber = e.itemQuantity
            e.orderCode = e.orderNumber
            e.refundType = Number(this.creatermaForm.refundType)
          })
          data.detailList = this.detailList
          // [{ 'imageUrl': this.sellerImageUrl, 'imageType': 0 }]
          data.imageList = imgList
          const id = this.id
          const rmaId = this.rmaId
          if (this.id) {
            data.returnsInfo = { rmaId, id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
          } else {
            data.returnsInfo = { remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
          }
          data.returnsTrack = { shipperAddress, orderCode }
          data.operating = 0
          console.log(data)
          this._saveReturnInfos(data)
        }
      })
    },
    // 审核
    submitReturn() {
      if (this.detailList.length <= 0) {
        this.$confirm('商品信息不能为空', '提示', {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        return
      }
      const data = {}
      var imgList = []
      if (this.creatermaForm.refundType === '1' || this.creatermaForm.refundType === '2') {
        this.detailList.map(e => {
          e.returnNumber = e.itemQuantity
          e.orderCode = e.orderNumber
          e.refundType = Number(this.creatermaForm.refundType)
        })
        this.refundDialogVisible = true
      } else {
        this.$refs['creatermaForm'].validate(async(valid) => {
          if (valid) {
            this.sellerFileList.map(e => {
              imgList.push({ imageUrl: e.url,
                imageType: 0 })
            })
            this.brcfilelist.map(e => {
              imgList.push({ imageUrl: e.url,
                imageType: 1 })
            })
            const { remark, returnDetailType, sellComments, orderCode, returnType, siteCode, shopCode, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
            this.detailList.map(e => {
              e.returnNumber = e.itemQuantity
              e.orderCode = e.orderNumber
              e.refundType = Number(this.creatermaForm.refundType)
            })
            data.detailList = this.detailList
            data.imageList = imgList
            const id = this.id
            if (this.id) {
              data.returnsInfo = { id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
            } else {
              data.returnsInfo = { remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
            }
            // data.returnsInfo = { remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
            data.returnsTrack = { shipperAddress, orderCode }
            data.operating = 1

            console.log(data)
            // this._saveReturnInfos(data)
          }
        })
      }
    },

    // 保存或者发布后的返回信息
    async _saveReturnInfos(data) {
      const { code, msg } = await saveReturnInfos(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
      }
    },
    changeInput(index) {
      var data = this.detailList[index]
      this.$set(this.detailList, index, data)
    },
    handlecChangeInput(index) {
      var data = this.orderData.itemList[index]
      this.$set(this.orderData.itemList, index, data)
    },
    changeNumber(index) {
      var data = this.detailList[index]
      // 订单金额不能大于原本金额
      if (parseFloat(data.itemPrice) > data.myItemPrice) {
        this.$confirm('你输入得金额不能大于原始金额', '提示', {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemPrice = data.myItemPrice
        return
      }
      if (parseFloat(data.otherRefund) > 20) {
        this.$confirm('您输入的不能大于20', '提示', {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.otherRefund = 0
        return
      }
      // 计算总金额
      data.refundAmount = (parseFloat(data.itemPrice) - Math.abs(parseFloat(data.promotionDiscount)) + parseFloat(data.otherRefund) - parseFloat(data.shippingPrice)).toFixed(2)
      this.$set(this.detailList, index, data)
    }

  }
}
</script>

<style scoped lang="scss">
/deep/ .el-upload--picture-card {
    width: 75px;
    height: 75px;
    cursor: pointer;
    line-height:75px;
}
.hide .el-upload--picture-card {
  display: none;
}
</style>
